<template>
  <div ref="modal" class="modal">
    <a-modal
        v-model:visible="visible"
        centered
        width="500px"
        :footer="null"
        :closable="false"
        :get-container="() => $refs.modal"
    >
      <div class="add">
        <div class="mWrap">
          <div class="scroll">
            <div class="title">Card Activation</div>
            <div class="line"></div>
            <div class="form">
              <div class="item">
                <div class="label">Card Number</div>
                <input type="text" v-model="form.bank_card_code">
              </div>
              <div class="item">
                <div class="label">
                  <div class="text">Card Name</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>As per passport</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <input type="text" v-model="form.u_name">
              </div>
              <div class="item">
                <div class="label">
                  <div class="text">Login E-mail</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>For log in to the product, your login credentials will be sent to the email address.</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <input type="text" v-model="form.login_email">
              </div>
              <div class="item">
                <div class="label">
                  <div class="text">Contact E-mail</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>If you only have one email address, you can use it for all contact purposes.</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <input type="text" v-model="form.email">
              </div>
              <div class="item">
                <div class="label">International Mobile Number</div>
                <div class="select">
                  <a-select
                      ref="select"
                      v-model:value="areaCode"
                      show-search
                      size="small"
                      :bordered="false"
                      :filter-option="filterCode"
                      style="width: 120px"
                      :options="codeList"
                      :field-names="{ label: 'code', value: 'code'}"
                  ></a-select>
                  <input v-model="phone">
                </div>
              </div>
              <div class="item">
                <div class="label">Country</div>
                <a-select
                    ref="select"
                    v-model:value="form.country_id"
                    show-search
                    size="small"
                    :bordered="false"
                    :filter-option="filterOption"
                    style="width: 100%"
                    :options="countries"
                    :field-names="{ label: 'country_name', value: 'country_id'}"
                ></a-select>
              </div>
              <div class="item">
                <div class="label">Address Line 1</div>
                <input type="text" v-model="form.area_line_1">
              </div>
              <div class="item">
                <div class="label">Address Line 2</div>
                <input type="text" v-model="form.area_line_2">
              </div>
              <div class="item">
                <div class="label">City</div>
                <input type="text" v-model="form.city">
              </div>
              <div class="item">
                <div class="label">State/ Province/ Region</div>
                <input type="text" v-model="form.province">
              </div>
              <div class="item">
                <div class="label">Zip/ Postal Code</div>
                <input type="text" v-model="form.post_code">
              </div>
              <div class="item">
                <div class="label">Passport</div>
                <a-upload
                    v-model:file-list="fileList"
                    name="avatar"
                    accept="image/jpg,image/jpeg,image/png"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :before-upload="beforeUpload"
                    :customRequest="handleUpload"
                    @change="handleChange"
                >
                  <img v-if="form.purpose" :src="form.purpose" class="passport"/>
                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                  </div>
                </a-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="btnWrap">
          <div class="btn" @click="visible = false">Cancel</div>
          <div class="btn confirm" @click="handleSubmit">Confirm</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue'
  import { message } from 'ant-design-vue'
  import Compressor from 'compressorjs'
  import { countryList, getCountryCode, uploadImg, saveUserAuth } from '@/request/api'
  import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';

  const visible = ref(false)

  const props = defineProps(['contract'])

  const emits = defineEmits(['added'])

  defineExpose({ visible, getAreaCode, getCountries })

  const form = reactive({
    bank_card_code: '',
    u_name: '',
    login_email: '',
    email: '',
    country_id: '',
    mobile: '',
    area_line_1: '',
    area_line_2: '',
    city: '',
    province: '',
    post_code: '',
    purpose: ''
  })

  function handleSubmit() {
    if(form.bank_card_code == '') {
      message.warning('Please Enter Card Number')
      return
    }
    if(form.u_name == '') {
      message.warning('Please Enter Card Name')
      return
    }
    if(form.login_email == '') {
      message.warning('Please Enter Login E-mail')
      return
    }
    if(form.email == '') {
      message.warning('Please Enter Contact E-mail')
      return
    }
    if(areaCode.value == '') {
      message.warning('Please select an international area code')
      return
    }
    if(phone.value == '') {
      message.warning('Please Enter Mobile Number')
      return
    }
    if(form.country_id == '') {
      message.warning('Please select a country')
      return
    }
    if(form.area_line_1 == '') {
      message.warning('Please Enter Address Line 1')
      return
    }
    if(form.area_line_2 == '') {
      message.warning('Please Enter Address Line 2')
      return
    }
    if(form.city == '') {
      message.warning('Please Enter City')
      return
    }
    if(form.province == '') {
      message.warning('Please Enter Province')
      return
    }
    if(form.post_code == '') {
      message.warning('Please Enter Postal Code')
      return
    }
    if(form.purpose == '') {
      message.warning('Please Upload Your Passport Photo')
      return
    }
    form.address = sessionStorage.getItem('address')
    form.mobile = areaCode.value + ' ' + phone.value
    let zuDisable = false
    saveUserAuth(form).then(async res => {
      if(res.success) {
        if(zuDisable) {
          return
        }
        zuDisable = true
        const { gasPrice, conContract } = props.contract
        try {
          const res1 = await conContract.AddBankCard(res.data, {
            gasPrice,
            gasLimit: 400000
          })
          if(res1) {
            zuDisable = false
            setTimeout(() => {
              message.success('Operation Successful')
              visible.value = false
              emits('added')
              form.bank_card_code = ''
              form.u_name = ''
              form.login_email = ''
              form.email = ''
              form.country_id = ''
              areaCode.value = ''
              form.mobile = ''
              phone.value = ''
              form.area_line_1 = ''
              form.area_line_2 = ''
              form.city = ''
              form.province = ''
              form.post_code = ''
              form.purpose = ''
              fileList.value = []
              loading.value = false
            }, 1000)
          }
        } catch (e) {
          zuDisable = false
          message.error('Operation Failed')
        }
      } else {
        message.warning(data.msg)
      }
    })
  }

  function beforeUpload(file) {
    return new Promise(resolve => {
      new Compressor(file, {
        quality: 0.2,
        success(res) {
          const file = new File([res], res.name, { type: res.type })
          if(file.size > 5 * 1024 * 1024) {
            message.warning(i18n.global.t('tips5'))
            return
          }
          resolve(file)
        },
        error(err) {
          console.log(err.message)
        }
      })
    })
  }

  function handleUpload(file) {
    const formData = new FormData();
    formData.append("file", file.file);
    uploadImg(formData).then(res => {
      if (res.success) {
        form.purpose = res.data.http_url;
      } else {
        message.error(i18n.global.t('doFail'))
      }
    })
  }

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }
    if (info.file.status === 'done') {
      loading.value = false;
    }
  }

  const countries = ref([])
  function getCountries() {
    countryList().then(res => {
      if(res.success) {
        countries.value = res.data
      }
    })
  }

  const codeList = ref([])
  function getAreaCode() {
    getCountryCode().then(res => {
      if(res.success) {
        codeList.value = res.data
      }
    })
  }

  const areaCode = ref('')
  const phone = ref('')

  const fileList = ref([])
  const imageUrl = ref('')
  const loading = ref(false)

  function filterOption(input, option) {
    return option.country_name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  function filterCode(input, option) {
    return option.code.indexOf(input) >= 0
  }
</script>

<style lang="less" scoped>
.modal{
  ::v-deep .ant-modal-content{
    background: transparent;
    box-shadow: none;

    .ant-modal-body{
      padding: 0;
    }
  }
  ::v-deep .ant-modal-mask{
    background: rgba(0, 0, 0, 0.8);
  }
  .add{
    color: #ffffff;

    .mWrap{
      height: 500px;
      margin-bottom: 24px;
      padding: 26px 0;

      .scroll{
        height: 100%;
        overflow-y: auto;

        .title{
          font-size: 22px;
          text-align: center;
          line-height: 20px;
          margin-bottom: 23px;
        }
        .line{
          margin: 0 16px 36px 16px;
          border-radius: 12px;
          height: 2px;
          background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
        }
        .form{
          padding: 0 30px;

          .item{
            min-height: 44px;
            border-radius: 7px;
            border: 1.5px solid #FFF;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%);
            margin-bottom: 35px;
            position: relative;
            padding: 10px 12px 0 12px;

            .label{
              font-size: 14px;
              color: #697377;
              position: absolute;
              left: 9px;
              top: -9px;
              line-height: 1;
              padding: 0 10px 0 4px;
              background: #000000;
              display: flex;
              align-items: center;

              img{
                width: 8px;
                margin-left: 8px;
              }
            }
            input{
              width: 100%;
              background: transparent;
              font-size: 15px;
            }
            .select{
              display: flex;
              align-items: center;
            }
            .passport{
              height: 198px;
              border-radius: 2px;
            }
          }
          .item:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .btnWrap{
      display: flex;
      justify-content: center;

      .btn{
        width: 112px;
        height: 44px;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #697377;
        border-radius: 18px;
        border: 1.5px solid #ffffff;
        color: #ffffff;
        cursor: pointer;
      }
      .confirm{
        margin-left: 60px;
        border: none;
        background: #06C8A1;
      }
    }
  }
}
</style>
