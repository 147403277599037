<template>
  <div class="card">
    <div class="container">
      <Menu/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Activate,</span> Meta Genesis Card
        </div>
        <div class="center bWrap">
          <div class="title">
            <img src="@/assets/imgs/icon_card.png">
            <div class="text">Card Details</div>
          </div>
          <div class="list">
            <div class="item" @click="openAdd">
              <div class="left">
                <img src="@/assets/imgs/icon_add.png">
              </div>
              <div class="right">
                <div class="label">Card Number</div>
                <div class="code">0000 0000 0000 0000</div>
                <div class="info">
                  <div class="group">
                    <div class="label">Card Name</div>
                    <div class="value">- Name -</div>
                  </div>
                  <div class="group">
                    <div class="label">Card Status</div>
                    <div class="value">- Status -</div>
                  </div>
                  <div class="group">
                    <div class="label">Last Update</div>
                    <div class="value">- Date -</div>
                  </div>
                </div>
                <div class="add">
                  <img src="@/assets/imgs/icon_add.png">
                  <div class="text">Add New Card</div>
                </div>
              </div>
            </div>
            <div
                v-for="(item,index) in cardList"
                :key="index"
                :class="{ activated: item.is_auth == 1, waiting: item.is_auth == 0, failed: item.is_auth == 2 }"
                class="item activated"
            >
              <div class="left box">
                <div class="top">
                  <div class="name">Meta Genesis Card</div>
                  <div class="code">**** **** **** {{ item.bank_card_code.substring(item.bank_card_code.length - 4) }}</div>
                </div>
                <div class="bottom">
                  <div class="group">
                    <div class="label">Card Name</div>
                    <div class="value">{{ item.u_name }}</div>
                  </div>
                  <div class="group">
                    <div class="label">Card Status</div>
                    <div v-if="item.is_auth == 1" class="value">Activated</div>
                    <div v-if="item.is_auth == 2" class="value">Failed (Check Your Email)</div>
                    <div v-if="item.is_auth == 0" class="value">Waiting For Approval</div>
                  </div>
                </div>
                <img v-if="item.is_auth == 1" class="board" src="@/assets/imgs/board1.png">
                <img v-if="item.is_auth == 2" class="board" src="@/assets/imgs/board2.png">
                <img v-if="item.is_auth == 0" class="board" src="@/assets/imgs/board3.png">
              </div>
              <div class="right">
                <div class="label">Card Number</div>
                <div class="code">{{ item.bank_card_code }}</div>
                <div class="info">
                  <div class="group">
                    <div class="label">Card Name</div>
                    <div class="value">{{ item.u_name }}</div>
                  </div>
                  <div class="group">
                    <div class="label">Card Status</div>
                    <div v-if="item.is_auth == 1" class="value">Activated</div>
                    <div v-if="item.is_auth == 2" class="value">Failed</div>
                    <div v-if="item.is_auth == 0" class="value">Pending</div>
                  </div>
                  <div class="group">
                    <div class="label">Last Update</div>
                    <div class="value">{{ item.timestamp }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <a :href="'#/card/detail?id=' + item.id" class="add">
                    <span class="icon">
                      <img src="@/assets/imgs/icon_refresh.png">
                      <img src="@/assets/imgs/icon_refresh_active.png">
                    </span>
                    <span class="text">View Card Details</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--添加银行卡弹框-->
    <Add ref="add" :contract="contract" @added="getData"/>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'
  import Add from './Add'
  import { getUserCardList } from '@/request/api'

  const router = useRouter()

  const add = ref(null)
  function openAdd() {
    add.value.visible = true
    add.value.getAreaCode()
    add.value.getCountries()
  }

  const contract = ref({})
  let address = ''
  const cardList = ref([])
  function getContract(obj){
    contract.value = obj
    address = obj.address
    getData()
  }

  function getData() {
    getUserCardList({
      address
    }).then(res => {
      if(res.success) {
        cardList.value = res.data
      }
    })
  }
</script>

<style lang="less" scoped>
.card{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .center{
        padding: 20px 24px 0 24px;
        min-height: calc(100vh - 210px);

        .title{
          display: flex;
          align-items: center;
          font-size: 17px;
          margin-bottom: 28px;

          img{
            width: 21px;
            margin-right: 17px;
          }
        }
        .list{
          .item{
            height: 167px;
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 27px;

            .left{
              width: 300px;
              height: 172px;
              border-radius: 8px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            .box{
              padding: 14px 0 12px 20px;
              display: flex;
              flex-direction: column;
              color: #000000;
              justify-content: space-between;

              .top{
                font-size: 13px;
                line-height: 19px;

                .name{
                  margin-bottom: 4px;
                }
              }
              .bottom{
                display: flex;
                align-items: center;

                .group{
                  .label{
                    color: #101010;
                    font-size: 12px;
                    margin-bottom: 6px;
                    opacity: 0.8;
                  }
                  .value{
                    font-size: 13px;
                  }
                }
                .group:first-child{
                  margin-right: 26px;
                }
              }
              .board{
                width: 36px;
                position: absolute;
                right: 32px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .right{
              width: 604px;
              background: linear-gradient(270deg, rgba(196, 196, 196, 0.00) 0%, rgba(196, 196, 196, 0.10) 36.46%);
              padding: 13px 10px 0 96px;

              .label{
                font-size: 12px;
                color: #697377;
                line-height: 20px;
              }

              .code{
                font-size: 24px;
                margin-bottom: 18px;
              }
              .info{
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .group{
                  width: 132px;

                  .label{
                    margin-bottom: 4px;
                  }
                  .value{
                    font-size: 14px;
                    line-height: 17px;
                  }
                }
                .group:last-child{
                  width: 200px;
                }
              }
              .add{
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img{
                  width: 19px;
                  margin-right: 10px;
                }

                .text{
                  font-size: 13px;
                }
              }
              .bottom{
                display: flex;
                justify-content: flex-end;

                a.add{
                  color: #ffffff;

                  .icon{
                    margin-right: 6px;
                    img{
                      margin-right: 0;
                    }
                    img:last-child{
                      display: none;
                    }
                  }
                }
                a.add:hover{
                  color: #06C8A1;

                  .icon{
                    img:first-child{
                      display: none;
                    }
                    img:last-child{
                      display: block;
                    }
                  }
                }
              }
            }
          }
          .item:first-child{
            cursor: pointer;

            .left{
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border: 2px solid #000000;
              background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

              img{
                width: 25px;
              }
            }
          }
          .activated{
            .left{
              background: url("~@/assets/imgs/card_bg1.png") no-repeat center center;
              background-size: 102% 102%;
              border: 2px solid #06C8A1;
            }
            .right{
              background: linear-gradient(270deg, rgba(6, 200, 161, 0.00) 0%, rgba(6, 200, 161, 0.10) 36.46%);
            }
          }
          .failed{
            .left{
              background: url("~@/assets/imgs/card_bg2.png") no-repeat center center;
              background-size: 102% 102%;
              border: 2px solid #33F88F;
            }
            .right{
              background: linear-gradient(270deg, rgba(51, 248, 143, 0.00) 0%, rgba(51, 248, 143, 0.10) 36.46%);
            }
          }
          .waiting{
            .left{
              background: url("~@/assets/imgs/card_bg3.png") no-repeat center center;
              background-size: 102% 102%;
              border: 2px solid #ffffff;
            }
            .right{
              background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 36.46%);
            }
          }
        }
      }
    }
  }
}
</style>
